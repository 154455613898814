import {
  NewsItem,
  NewsCategoryImage,
  NewsCategory,
  NewsCategoryClild,
} from '~/types/blog'


export const convertImage = (data: any): NewsCategoryImage => {
  return {
    id: data.ID,
    name: data.Name,
    src: data.FilePath,
    cdnSrc: data.CDNFileKey,
  }
}

export const convertImages = (data: any): NewsCategoryImage[] => {
  if (!data) return []

  return data.map((item: any) => convertImage(item))
}

export const convertNewsItem = (data: any): NewsItem => {
  return {
    id: data.ID,
    name: data.Name,
    author: data.Author,
    imageUrl: data.ImageUrl,
    keywords: data.Keywords,
    title: data.LinkTitle || data.Title || data.Url || data.Name,
    pathUrl: data.PathUrl,
    url: data.Url,
    publicationDate: data.PublicationDate,
    tags: data.Tags ? data.Tags?.split('|')?.map((tag:string) => tag.trim()) : [],
    heading: data.Heading,
    text: data.Text,
    summary: data.Summary,
    useBlocks:data.UseBlocks,
    blocks: data.Blocks
  }
}

export const convertNewsItems = (data: any): NewsItem[] => {
  return data.map((item: any) => convertNewsItem(item))
}

export const convertNewsNewsCategoryChild = (data: any): NewsCategoryClild => {
  return {
    id: data.ID,
    name: data.Name,
    title: data.LinkTitle || data.Title || data.Url || data.Name,
    url: data.Url
  }
}

export const convertNewsCategory = (data: any): NewsCategory | null => {
  if (!data) return null

  return {
    id: data.ID,
    childCategories:
      data.ChildCategories?.filter(
        (category: any) => !category.HideLink
      )?.map((item: any) => convertNewsNewsCategoryChild(item)) ?? [],
    newsItems: data.NewsItems ? convertNewsItems(data.NewsItems) : [],
    newsItemsCount: data.NewsItemsCount ?? 0,
    images: data.Images ? convertImages(data.images) : [],
  }
}
